import classNames from 'classnames'
import { Chevron } from '@vhl/icons'

const PaginationButton = ({
  totalPages,
  currentPage,
  setCurrentPage,
}: {
  totalPages: number
  currentPage: number
  setCurrentPage: (page: number) => void
}) => {
  function Button({
    active = false,
    onClick,
    children,
  }: {
    active?: boolean
    onClick?: () => void
    children: React.ReactNode
  }) {
    const buttonClass = classNames(
      'px-4 py-3 rounded-full',
      { 'text-white bg-blue-500': active },
      { 'text-black bg-white hover:bg-blue-500 hover:text-white': !active }
    )
    return (
      <button className={buttonClass} onClick={onClick}>
        {children}
      </button>
    )
  }
  return (
    <div className="flex items-center space-x-5">
      {totalPages > 1 && (
        <span className={`${currentPage > 1 && 'cursor-pointer'}`}>
          <Chevron
            className="rotate-90"
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1)
              }
            }}
          />
        </span>
      )}
      {Array.from({ length: totalPages }).map((_, index) => (
        <Button
          key={index}
          active={index + 1 === currentPage}
          onClick={() => setCurrentPage(index + 1)}
        >
          {index + 1}
        </Button>
      ))}
      {totalPages > 1 && (
        <span
          className={`${currentPage < totalPages && 'cursor-pointer'}`}
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1)
            }
          }}
        >
          <Chevron className="-rotate-90" />
        </span>
      )}
    </div>
  )
}

export default PaginationButton
