import useSWR from 'swr'
import dayjs from 'dayjs'
import { truncate, stripHTML } from '@/utils'
import { fetcherGraphQL } from '@/utils/fetch'
import { BASE_URL, PODCAST_TERM_ID } from '@/constants'

export function useTotalSearchPages(q: string, PER_PAGE: number = 20) {
  const fetcher = (query: string) => fetch(query).then(res => res.headers)
  const { data, error } = useSWR(
    `${BASE_URL}/wp-json/wp/v2/search?search=${q}&per_page=${PER_PAGE}`,
    fetcher
  )
  if (error) return 0
  if (!data) return 0
  const totalPages = parseInt(data.get('x-wp-totalpages') || '0')
  return totalPages !== 1 ? totalPages - 1 : 1
}

export function useSearchPost(
  q: string,
  page: number = 1,
  PER_PAGE: number = 20
) {
  const { data, error } = useSWR(
    `
  {
    posts(where: {search: "${q}"}, first: ${page * PER_PAGE}) {
      nodes {
        title
        excerpt
        slug
        modified
        categories {
          nodes {
            name
            slug
            parentDatabaseId
          }
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
          }
        }
        vhl_fields {
          podcastSpotifyLink
          podcastYoutubeLink
        }
      }
    }
  }`,
    fetcherGraphQL
  )
  let allPosts =
    data?.posts?.nodes &&
    data.posts.nodes.map((post: any) => ({
      title: post.title,
      excerpt: stripHTML(truncate(post.excerpt, 80)),
      image: {
        srcSet: post.featuredImage.node.srcSet,
        src: post.featuredImage.node.sourceUrl,
      },
      slug: post.slug,
      date: dayjs(post.modified).format('DD.MM.YYYY'),
      categories: post.categories.nodes,
      spotify: post.vhl_fields.podcastSpotifyLink,
      youtube: post.vhl_fields.podcastYoutubeLink,
    }))
  // if page != 1, slice page * per_page first posts to get the correct posts
  if (page !== 1) {
    allPosts?.splice(0, (page - 1) * PER_PAGE)
  }
  // filter podcast posts
  let podcasts,
    posts = []

  if (allPosts) {
    podcasts = allPosts?.filter((post: any) => {
      return post.categories.find(
        (category: any) => category.parentDatabaseId === PODCAST_TERM_ID
      )
    })
    posts = allPosts?.filter((post: any) => {
      return !post.categories.find(
        (category: any) => category.parentDatabaseId === PODCAST_TERM_ID
      )
    })
  }
  return {
    posts: posts || [],
    podcasts: podcasts || [],
    isLoading: !error && !data,
    error,
  }
}
