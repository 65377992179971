import { SERIES_TERM_ID, PODCAST_TERM_ID } from '@/constants'

export function getCategory(post: any, findSeries?: boolean) {
  // get categories by check if categories is array or contains nodes array
  const categories = Array.isArray(post.categories)
    ? post.categories
    : post.categories.nodes

  // if findSeries is true, we will find series category, if not, fallback to first category (sure to be topic)
  let category: any
  if (findSeries) {
    category = categories.find(
      (category: any) => category.parentDatabaseId === SERIES_TERM_ID
    )
    // if not found, return first category with type is 'topic'
    if (!category) {
      category = categories[0]
      return { ...category, type: 'topic' }
    }
    // otherwise return series category
    return { ...category, type: 'series' }
  } else {
    // if findSeries is false, we will find first category
    category =
      categories.length > 0
        ? {
            name: categories[0].name,
            slug: categories[0].slug,
            type:
              categories[0].parentDatabaseId === SERIES_TERM_ID
                ? 'series'
                : 'topic',
            ...categories[0],
          }
        : { name: '', slug: '' }
  }

  return category
}

export function getPodcastsAndPosts(allPosts: any) {
  let podcasts: any[] = []
  let posts: any[] = []
  allPosts.forEach((post: any) => {
    const categories = Array.isArray(post.categories)
      ? post.categories
      : post.categories.nodes
    const isPodcast = categories.find(
      (category: any) => category.parentDatabaseId === PODCAST_TERM_ID
    )
    if (isPodcast) {
      podcasts.push(post)
    } else {
      posts.push(post)
    }
  })

  // return seperate podcast post and other posts
  return {
    podcasts,
    posts,
  }
}
