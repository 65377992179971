import { useBreakpoints } from '@/hooks/useBreakpoints'
import { ArrowUpRight } from '@vhl/icons'
import { motion } from 'framer-motion'

const PodcastCard = ({ post }: any) => {
  const { isDesktop } = useBreakpoints()
  function openYoutubeLink() {
    if (typeof window === 'undefined') return
    window.open(post.youtube, '_blank')
  }
  return (
    <div className="flex flex-col space-y-2">
      <div className="relative">
        <img
          src={post.image.src}
          srcSet={post.image.srcSet}
          alt={post.title}
          onClick={() => !isDesktop && openYoutubeLink()}
        />
        {/* Create overlay when hover */}
        <motion.div
          className="absolute inset-0 hidden lg:block group"
          initial={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
          whileHover={{ backgroundColor: 'rgba(18, 18, 18, 0.50)' }}
        >
          <div className="flex items-center justify-center z-[2] h-full">
            <button
              onClick={openYoutubeLink}
              className="flex items-center justify-center duration-300 scale-0 primary hover:ghost group-hover:scale-100 stroke-white hover:stroke-black"
            >
              <span>Xem trên Youtube</span>
              <ArrowUpRight className="mt-1" height={28} width={28} />
            </button>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col space-y-2 lg:flex-row">
        <a
          href={post.youtube}
          target="_blank"
          className="w-full transition lg:w-1/2 md:subheading-6 lg:subheading-5 subheading-5 hover:text-blue-500"
        >
          {post.title}
        </a>
        <div className="flex items-center justify-end w-full text-gray-400 md:justify-start lg:justify-end lg:items-start lg:text-black lg:w-1/2">
          <div className="flex items-center space-x-1 border-b-[1px] border-gray-400 lg:border-black">
            <a
              href={post.spotify}
              target="_blank"
              className="transition caption-sm lg:subheading-6 hover:text-gray-600"
            >
              Nghe trên Spotify
            </a>
            <ArrowUpRight
              width={20}
              height={20}
              className="stroke-gray-400 lg:stroke-black"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PodcastCard
