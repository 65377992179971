import { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { useSearchPost, useTotalSearchPages } from '@/hooks/useSearch'
import SearchBox from '@/components/shared/SearchBox'
import Post from '@/components/pages/search/Post'
import PaginationButton from '@/components/pages/search/PaginationButton'
import PodcastCard from '@/components/pages/search/PodcastCard'
import SEO from '@/components/shared/SEO'

const Search = () => {
  const location = useLocation()
  const PER_PAGE: number = 8
  const [page, setPage] = useState<number>(1)
  const [searchText, setSearchText] = useState<string | null>('')
  const totalPages = useTotalSearchPages(searchText || '', PER_PAGE)
  const { posts, podcasts, isLoading, error } = useSearchPost(
    searchText || '',
    page,
    PER_PAGE
  )
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setSearchText(searchParams.get('q'))
  }, [location.search])
  return (
    <>
      <SEO title={`${searchText} - Tìm kiếm`} />
      <div className="py-12 base-container">
        <div className="flex justify-center items-center w-full">
          <SearchBox
            type="transparent"
            parentClass="flex justify-center items-center w-full md:max-w-[80%] lg:max-w-[40%]"
            className="bg-white border-t-0 border-l-0 border-r-0 border-b-[1px] border-gray-500 py-[4px] font-sans pr-[25px] w-full font-bold"
            defaultValue={searchText}
          />
        </div>
        <div className="flex flex-col items-center justify-center mt-[90px] space-y-2 ">
          <h1 className="px-10 text-blue-500 break-words heading-4 md:heading-1">
            {searchText}
          </h1>
          <p>
            {isLoading ? 'Đang tải...' : `${posts.length} kết quả tìm thấy`}
          </p>
        </div>
        <div className="flex flex-col">
          <h3 className="mb-2 font-bold heading-3">Bài viết</h3>
          <hr />
          <div className="grid grid-cols-1 gap-y-8 mt-12 md:grid-cols-4 md:gap-x-4 md:gap-y-14">
            {isLoading && <p>Loading...</p>}
            {error && <p>Error!</p>}
            {posts &&
              posts.map((post: any, index: number) => (
                <Post key={index} post={post} />
              ))}
          </div>
          {totalPages > 1 && (
            <div className="flex justify-center mt-12 lg:mt-28">
              <PaginationButton
                totalPages={totalPages}
                currentPage={page}
                setCurrentPage={setPage}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <h3 className="mb-2 font-bold heading-3">Podcast</h3>
          <hr />
          <div className="grid grid-cols-1 gap-y-8 mt-12 md:grid-cols-3 lg:grid-cols-2 md:gap-x-4">
            {isLoading && <p>Loading...</p>}
            {error && <p>Error!</p>}
            {podcasts &&
              podcasts.map((post: any, index: number) => (
                <PodcastCard post={post} key={index} />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

// create browser wrapper component and only render Search component if window available

export default () => {
  const [isBrowser, setIsBrowser] = useState(false)
  useEffect(() => {
    setIsBrowser(true)
  }, [])
  if (!isBrowser) {
    return null
  }
  return <Search />
}
