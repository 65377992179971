import PostCard from '@/components/shared/Post/PostCard'
import { getCategory } from '@/utils/category'

const Post = ({ post }: any) => {
  const category = getCategory(post)
  return (
    <PostCard
      className="flex space-x-3 md:space-x-0 md:space-y-4 md:flex-col"
      {...post}
      image={post.image}
      category={category}
    >
      <>
        <div className="flex w-1/3 md:w-full">
          <PostCard.Image
            isGatsbyImage={false}
            className="object-cover h-full w-full md:h-[200px] lg:h-[300px] cursor-pointer"
          />
        </div>
        <div className="flex flex-col w-2/3 space-y-2 md:w-full">
          <PostCard.Date />
          <PostCard.Title heading="heading-6 lg:subheading-5" />
          <PostCard.Category />
        </div>
      </>
    </PostCard>
  )
}

export default Post
